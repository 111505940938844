<template>
  <div>
    <!-- <v-img v-if="assets" :src="assets.content_image_url" contain max-width="37%" class="content-img hidden-md-and-down "></v-img> -->
    <div class="background" :style="{ backgroundImage: 'url(' + assets.background_url + ')' }"></div>
    
    <v-form class="content" :class="`${!assets.background_url&&'grey lighten-1'} ${assets.dark ? 'white--text' : 'black--text'}`">
      <v-row class="pr-12 pt-8 pl-12 sm:pl-0" >
        <v-col cols="12" md="4"  class="content-image  " order="1">
          <v-img
            alt="2 cols"
            class="shrink mr-2 "
            contain
            :src="assets.content_image_url"
            transition="scale-transition"
          />
        </v-col>
        <v-col 
          cols="12" 
          md="4"  
          class="content-info " 
          order="2" 
          :class="assets.columns!==3&&'oculta'"

        >
          <!-- <pre> {{JSON.stringify(formatDataCustomFields,null,"\t")}} </pre> -->
          <!-- <pre> {{JSON.stringify(assets.form.fields,null,"\t")}} </pre> -->
          <v-card class="py-8 px-4" color="#F0F0F0" >
            <div v-html="assets.content"></div>
          </v-card>
        </v-col>
        <v-col cols="12" md="4"  class="content-form " order="3">
          <!-- <div id="page-wrap" class="bgInputs pa-6 rounded-lg" :class="assets.dark? 'isDark' : 'isWhite'"> -->
          <div id="page-wrap" class="bgInputs pa-6 rounded-lg" >
            <!-- <p class="text-body-2" v-html="assets.form_text"></p> -->
            <p class="text-body-2 form-content" v-html="assets.form.content"></p>
            <p class="text-subtitle-2 mb-8" >Ingresa tus datos personales</p>
            <v-form @submit.prevent="sendData()" v-model="valid" ref="form" >

              <v-text-field
                v-model="inputs.name"
                :rules="rules"
                placeholder="Nombre"
                persistent-placeholder
                outlined
                rounded
                dense
                :dark="assets.dark"
                :color="assets.dark?'white':'black'"

              >
                <template v-slot:label>
                  <span >Nombre*</span>
                </template>
              </v-text-field>

              <v-text-field
                v-model="inputs.last_name"
                :rules="rules"
                placeholder="Apellidos"
                persistent-placeholder
                outlined
                rounded
                dense
                :dark="assets.dark"
                :color="assets.dark?'white':'black'"

              >
                <template v-slot:label>
                  <span >Apellidos*</span>
                </template>
              </v-text-field>

              <v-text-field
                v-model="inputs.email"
                :rules="emailRules"
                placeholder="example@example.com"
                persistent-placeholder
                outlined
                rounded
                dense
                :dark="assets.dark"
                :color="assets.dark?'white':'black'"

              >
                <template v-slot:label>
                  <span >Email*</span>
                </template>
              </v-text-field>

              <v-text-field
                v-model="email_confirm"
                :rules="verifyEmailRule"
                placeholder="example@example.com"
                persistent-placeholder
                outlined
                rounded
                dense
                :dark="assets.dark"
                :color="assets.dark?'white':'black'"
              >
              <template v-slot:label>
                <span >Confirma tu email*</span>
              </template>
              </v-text-field>

              <!-- :placeholder="!assets.validate_serial_number ? 'xxxxxxxxxxxx' : 'xxx-xxx-xxx'"
              :rules="!assets.validate_serial_number ? numberRules : hpNumberRules" -->
              <v-text-field
                v-model="inputs.serial_number"
                :placeholder="'*'.repeat(assets.num_max_serie)"
                :rules="hpNumberRules"
                persistent-placeholder
                outlined
                rounded
                dense
                :dark="assets.dark"
                :color="assets.dark?'white':'black'"
                v-on:keyup="handleInputOnChange"
              >
              <template v-slot:label>
                  <span >{{assets.label_serie?assets.label_serie:"Numero de serie"}}*</span>
                <v-tooltip top v-if="assets.show_serial_number_help">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="complement"
                      class="ml-2"
                      v-bind="attrs"
                      v-on="on"
                      size="19"
                    >
                      mdi-help-circle-outline
                    </v-icon>
                  </template>
                  
                  <img :src="require(`@/assets/${srcTooltipImage}`)" alt="tooltip">
                </v-tooltip>
              </template>
              </v-text-field>
              
              <template v-if="assets.form">
                <div  v-for="(field,index) in customInputs" :key="`field_${index}`" class="">
                  <v-text-field
                    v-if="field.type==='text' && customInputs[index]"
                    persistent-placeholder
                    outlined
                    rounded
                    dense
                    :placeholder="field.name"
                    v-model="customInputs[index].value"
                    :rules="field.required?rules:noRules"
                    :dark="assets.dark"
                    :color="assets.dark?'white':'black'"
                  >
                  <template v-slot:label>
                      <span >{{field.name}} {{field.required?"*":"(Opcional)"}}</span>
                  </template>
                  </v-text-field>
  
                  <v-select 
                    v-else-if="field.type==='select'"
                    :items="field.options"
                    item-text="name"
                    item-value="name"
                    class="black--text"
                    placeholder="Selecciona una opción"
                    persistent-placeholder
                    outlined
                    rounded
                    dense
                    v-model="customInputs[index].value"
                    :rules="field.required?rules:noRules"
                    :dark="assets.dark"
                    :color="assets.dark?'white':'black'"
                  >
                    <template v-slot:label>
                      <span >{{field.name}} {{field.required?"*":"(Opcional)"}}</span>
                    </template>
                  </v-select>
  
                  <v-menu 
                    v-else-if="field.type==='date'"
                    :ref="`menu_${index}`"
                    :close-on-content-click="true"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="customInputs[index].value"
                        placeholder="2020/02/10"
                        persistent-placeholder
                        readonly
                        dense
                        rounded
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        :dark="assets.dark"
                        :color="assets.dark?'white':'black'"
                        :rules="field.required?rules:noRules"
                      >
                      <template v-slot:label>
                        <span >{{field.name}} {{field.required?"*":"(Opcional)"}}</span>
                      </template>
                    </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="customInputs[index].value"
                      no-title
                      scrollable
                      :allowed-dates="(date) => date <= new Date().toISOString().substr(0, 10)"
                      locale="es-mx"
                    >
                    </v-date-picker>
                  </v-menu>

                  <div class="" v-else-if="field.type==='file'">
                    <v-file-input
                      :loading="field.loading"
                      accept="image/*"
                      counter
                      small-chips
                      :placeholder="`${field.name} ${field.required?'*':'(Opcional)'}`"
                      :success-messages="field.uploadImageSuccesMessages"
                      :error-messages="field.uploadImageErrorMessages"
                      @change="submitImage(index,$event)"
                      :rules="field.required?rules:noRules"
                    ></v-file-input>
                    <v-img
                      v-if="field && field.value!==''"
                      alt="ticket"
                      class="shrink mr-2 py-4 hidden-md-and-down"
                      contain
                      :src="field.value"
                      transition="scale-transition"
                      width="50"
                    />
                  </div>
  
                </div>
              </template>
              <ReCaptchaComponent @getRecaptchaToken="getRecaptchaToken" ref="reCaptcha"/>
              <!-- <v-btn outlined @click="onResetCaptcha">
                RESET
              </v-btn> -->

              <div class="d-flex" v-if="assets.checkbox_1">
                <v-checkbox hide-details color="complement" class="ma-0 ml-1" :rules="rules"></v-checkbox>
                <p class="check-text ma-0" v-html="assets.content_checkbox_1" ></p>
              </div>
              <div class="d-flex" v-if="assets.checkbox_2" >
                <v-checkbox hide-details color="complement" class="ma-0 ml-1" :rules="rules"></v-checkbox>
                <p class="check-text ma-0" v-html="assets.content_checkbox_2" ></p>
              </div>
              <!-- <v-btn color="secondary" class="mt-6 py-6" block rounded type="submit" :disabled="!valid"> -->
              <v-btn color="secondary" class="mt-6 py-6" block rounded type="submit" :disabled="!isValidCaptcha">
                <span class="button-text mb-0">Continuar</span>
              </v-btn>
            </v-form>
          </div>
          <div class="modalWorking" v-if="assets.pop_up" >
            <div v-html="assets.pop_up_text" class="black--text" :class="$route.params.name === `vix`&&'vixStyle'">
            </div>
          </div>
          
          <div class="modalWorking" v-if="isValidating" >
            <div class="d-flex justify-center align-center black--text">
              <p>Validando datos...</p>
              <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-form>

    <v-snackbar
      v-model="snackbar"
      :color="snackColor"
      class="white--text"
      bottom
    >
      {{ snackText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          class="button-text"
          color="secondary"
          v-bind="attrs"
          @click="snackbar = false"
        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>

    
  </div>
</template>

<script>
var md5 = require('md5');
import ReCaptchaComponent from '@/components/ReCaptcha.vue'
export default {
  name: 'HomeView',
  components:{
      ReCaptchaComponent
    },
    props:['assets'],
    data() {
      return {
        
        inputs: {
          email: '',
          serial_number: '',
          name:"",
          last_name:"",
          recaptcha_token:""
        },
        isValidCaptcha:false,

        customInputs:[],
        email_confirm: '',
        noRules: [v => true || 'Campo requerido'],
        rules: [v => !!v || 'Campo requerido'],
        numberRules:[
          (value) => !!value || 'Campo requerido',
          (value) => value.length == '10' || 'Debe contener 10 digitos',
        ],
        hpNumberRules:[
          (value) => !!value || 'Campo requerido',
          (value) => value.length == `${this.assets.num_max_serie}` || `Debe contener ${this.assets.num_max_serie} digitos`,
        ],
        emailRules: [v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Debe ser un email correcto'],
        verifyEmailRule: [v => !!v && this.inputs.email===this.email_confirm || 'Verificar email'],
        valid: false,
        snackbar: false,
        snackColor: '',
        snackText: '',
        menu: false,
        isValidating:false
      }
    },
    mounted(){
      this.axios.get('https://api.ipify.org?format=json')
        .then(response=>{
          console.log(response.data.ip);
          this.inputs.ip=response.data.ip;
        })
    },
    methods: {
      getRecaptchaToken({token}){
        if (!token) {
          this.isValidCaptcha = false
          this.inputs.recaptcha_token = "" 
          return
        }

        this.isValidCaptcha = true
        this.inputs.recaptcha_token = token 
      },
      async sendData(){
        if (this.assets.pop_up === true) {
          return
        }

        this.$refs.form.validate()
        // this.getCode('TESTINGCODE', this.inputs.email)
        // this.$router.push(`/${this.$route.params.name}/code`)
        
        if(!this.valid == true) return
        // console.log('%cHomeView.vue line:351 this.formatDataCustomFields', 'color: white; background-color: #007acc;', this.formatDataCustomFields);
        try {
          var key="ApPL1AB"
          var tail="C1CkeALo"      
          const randomBetweenSevenAndThree = Math.floor(Math.random() * (7 - 3 + 1)) + 3;
          this.isValidating= true
          await new Promise(resolve => setTimeout(resolve, randomBetweenSevenAndThree*1000));
          this.isValidating= false
          var checksum=md5(key+this.inputs.serial_number+tail)
          this.formatDataCustomFields.checksum=checksum
          this.formatDataCustomFields.ip=this.inputs.ip;
          const response = await this.axios.post(`/v1/redemption/${this.assets.id}`,this.formatDataCustomFields)
          console.log('%cHomeView.vue line:377 response.data', 'color: #26bfa5;', response.data); 
          this.getCode(response.data.code, this.inputs.email, response.data.thank_you_text_bottom,response.data.thank_you_text_top)
          this.$router.push(`/${this.$route.params.name}/code`)
          
        } catch (error) {
          this.onResetCaptcha()
          this.snackbar = true
          this.snackText = error.response.data.error
          this.snackColor = 'error'
          this.isValidating=false
        }
      },
      getCode(code,name,thank_you_text_bottom,thank_you_text_top){
        this.$emit('getCode', code, name, thank_you_text_bottom, thank_you_text_top)
      },
      currentDateTime() {
        const current = new Date();
        const date = current.getFullYear()+'-'+(current.getMonth()+1)+'-'+current.getDate();
        const dateTime = date;

        this.now = dateTime;
      },
      async submitImage(index,file) {
        console.log('%cHomeView.vue line:352 {file,index}', 'color: white; background-color: #007acc;', {file,index});
        if (!file) {
          return;
        }
        // uploadTiket
        try {
          const newCustomInputsSetLoading = [...this.customInputs]
          newCustomInputsSetLoading[index].loading = true
          this.customInputs = newCustomInputsSetLoading

          var formData = new FormData();
          formData.append("file", file);
          const {data} = await this.axios.post("/v1/ticket/upload",formData,{headers: {'Content-Type': 'multipart/form-data'}})
          console.log('%cHomeView.vue line:359 data', 'color: #26bfa5;', data);
          const newCustomInputs = [...this.customInputs]
          newCustomInputs[index].value = data.file
          newCustomInputs[index].loading = false
          newCustomInputs[index].uploadImageSuccesMessages = "Imagen guardada"

          this.customInputs = newCustomInputs
        } catch (error) {
          newCustomInputs[index].uploadImageErrorMessages = error
          this.uploadImageErrorMessages = error
        }
      },
      handleInputOnChange() {
        this.inputs.serial_number = this.inputs.serial_number.toUpperCase()
      },
      onResetCaptcha(){
        this.$refs.reCaptcha.reset()
      }

    },
    computed:{
      formatDataCustomFields(){
        const customInputsArray = this.customInputs.map(input => {
          return {
            name:input.name,
            type:input.type,
            value:input.value?input.value:""
          }
        })
        return {
          ...this.inputs,
          data:customInputsArray
        }
      },
      srcTooltipImage(){
        const landingsSpecialImage = ["vix","hp","directtv"];
        const landingName = this.$route.params.name 

        if (landingsSpecialImage.includes(landingName)) {
          return "HpBars.jpg"
        }
        return "tootltip.png" 
      }
    },
    created() {
      this.currentDateTime()
      this.customInputs = [...this.assets.form.fields]
    },
    watch:{
      customInputs(val){
        deep: true
      },
    }
  }
</script>

<style>

.check-text p{
  font-size: 0.6rem !important;
}
  *{
    /* border: 1px solid black; */
  }
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap'); 
.purple{
  /* height: 100vh; */
}
.content-image{
  height: 100%;
  position: sticky;
  top: 	40px;
}
.content-info p{
  font-size: 0.77rem;
}
.vixStyle{
  background: #5198D0 !important;
}
.vixStyle a{
  font-weight: 600;
  color: black !important;
}
.form-content p{
  line-height: 0.9rem;
  font-size: 0.65rem;
}
.content-form{
  position: relative;
  height: 100%;
  overflow: scroll;
}
.oculta{
  visibility: hidden;
}
.isDark{
  background-color: rgba(0, 0, 0, 0.4);
}
.isWhite{
  background-color: rgba(255, 255, 255, 0.4);
}
@media (max-width:960px) {
  .content-image{
    position: relative;
    top: 0;
  }
  .content-form{
    /* background: red !important; */
  }
}


  .background{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: fixed;
    height: 100vh;
    width: 100%;
    z-index: 1;
  }
  .content{
    position: relative; 
    z-index: 4;
    margin-bottom: 20px;
  }
  .content-img{
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 99;
  }
  .bgInputs{
    background: rgba(255, 255, 255, 0.4);
    max-height: none;
    overflow: auto;
  }
  .text-sm{
    font-size: 12px;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 1000px){
    .bgInputs{
      max-height: none;
    }
  }
  .v-tooltip__content {
    opacity: 1 !important;
    background-color: transparent !important;
  }

  .modalWorking{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color:rgba(0,0,0,0.3);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modalWorking div:first-child{
    width: 90%;
    /* height: 50%; */
    background: white;
    opacity: 100%;
    border-radius:20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 30px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  }
  @media (min-width: 800px) {
    .modalWorking div{
      /* width: 50%; */
    }
  }

</style>
