<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
      elevation="0"
      :value="enableToolbar"
      >
      <!-- @click="printAssets" -->
      <div class="d-flex align-center">
        <v-img
          alt="Logo"
          class="shrink mr-2 py-4 hidden-md-and-down pointer "
          contain
          :src="logo"
          transition="scale-transition"
          width="90"
          height="60"
          @click="$router.push({name:'home'})"
        />
        <v-img
          alt="Logo"
          class="shrink mr-2 py-4 hidden-lg-and-up pointer"
          contain
          :src="logo"
          transition="scale-transition"
          width="70"
          height="50"
          @click="$router.push({name:'home'})"
        />
      </div>

      <v-spacer></v-spacer>
      <!-- <router-link :to="{name:'terminos'}">Terminos</router-link>
      <router-link :to="{name:'politica'}">Politica</router-link> -->
      <v-btn
        @click="goToContactPage"
        color="secondary"
        class="button-text px-12 py-5"
        rounded
        depressed
        x-small
        v-if="assets && !!assets.contact_btn_text"
      >
        {{ assets.contact_btn_text }}
      </v-btn>
    </v-app-bar>

    <v-main v-if="!loading">
      <router-view :assets="assets" @getCode="getCode" @getLogo="getLogo"/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    logo: '',
    enableToolbar:true,
    assets: null,
    loading:true,
  }),
  mounted() {
    this.getAssets()
  },
  methods: {
    printAssets(){
      this.assets.dark = !this.assets.dark
    },
    goToNotFound(){
      this.enableToolbar = false
      this.$router.push({name:"not_found"})
    },
    async getAssets(){
      this.loading = true
      try {
        const response = await this.axios.get(`/v1/landing/${this.$route.params.name}`,{
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Content-Type': 'application/json',
          } 
        })
        this.$vuetify.theme.themes.light.primary = response.data.primary_color
        this.$vuetify.theme.themes.light.secondary = response.data.secondary_color
        this.$vuetify.theme.themes.light.complement = response.data.third_color?response.data.third_color:"blue"
        this.assets = response.data
        this.logo = response.data.logo_url
        // document.title = this.capitalize(response.data.name)
        // this.assets.dark = true
        document.title = response.data.title
        this.handleIcon(response.data.favicon_url)
        this.loading = false
              
        if (!this.assets.enabled) {
          this.goToNotFound()
        }
      } catch (error) {
        console.log('%cerror App.vue line:98 ', 'color: red; display: block; width: 100%;', error);
      }

    },
    getCode(code, name, thank_you_text_bottom, thank_you_text_top){
      this.assets.code = code
      this.assets.personMail = name
      console.log('%cApp.vue line:100 Object', 'color: white; background-color: #007acc;', {
        thank_you_text_bottom,
        thank_you_text_top,
      });
      if (thank_you_text_bottom) this.assets.thank_you_text_bottom = thank_you_text_bottom
      if (thank_you_text_top) this.assets.thank_you_text_top = thank_you_text_top
    },
    getLogo(url){
      this.logo = url
    },
    capitalize(word) {
      return word[0].toUpperCase() + word.substring(1).toLowerCase();
    },
    handleIcon(url){
      const favicon = document.getElementById("favicon");
      favicon.href = url;
      // favicon.href = "https://www.google.com/favicon.ico";
    },
    goToContactPage(){
      const link = this.assets.contact_btn_link?this.assets.contact_btn_link:"https://support.hp.com/mx-es/contact"
      window.open(link, "_blank");
    }
  },
};
</script>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap'); 
  a{
    color: v-bind('assets && assets.third_color?assets.third_color:"#007acc"') !important;
  }
  .pointer{
    cursor: pointer;
  }
  .button-text{
    text-transform: capitalize;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: normal
  }
  html, p{
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
  }
</style>
