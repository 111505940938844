<template>
   <vue-recaptcha
      ref="recaptcha"
      :sitekey="'6LdORAkjAAAAAOVeTo2QRCbYUhw6sBiblyQ3cwiL'"
      :loadRecaptchaScript="true"
      @verify="onCaptchaVerified"
      @expired="onCaptchaExpired"
    ></vue-recaptcha>
</template>

<script>
import  VueRecaptcha  from "vue-recaptcha";

export default {
  components:{
    VueRecaptcha
  },
  data(){
    return {
    }
  },
  methods: {
    reset(){
      this.$refs.recaptcha.reset();
      this.$emit("getRecaptchaToken",{token:null})  
    },
    onCaptchaExpired: function () {
      this.$refs.recaptcha.reset();
      this.$emit("getRecaptchaToken",{token:null})
    },
    onCaptchaVerified: function (recaptchaToken) {
      this.captchaVerified = true
      this.$emit("getRecaptchaToken",{token:recaptchaToken})
    },
  },
}
</script>

<style>

</style>