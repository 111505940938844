<template>
  <v-container class="grey lighten-4" fluid fill-height>
    <v-row justify="center">
      <h1 class="text-h2">ERROR!</h1>
    </v-row>
    <v-row justify="center">
      <h1 class="text-h4">NO SE ENCONTRO LA PAGINA</h1>
    </v-row>
    <v-row align="end" justify="center" class="" no-gutters>
      <v-icon class="icon">
        mdi-cactus
      </v-icon>
      <v-icon class="icon2">
        mdi-cactus
      </v-icon>
      <v-icon class="icon3">
        mdi-cactus
      </v-icon>
    </v-row>
    <v-row justify="center">
      <p class="text-caption">¡Vaya! Parece que tomaste un giro equivocado y terminaste en la tierra de las páginas perdidas. No te preocupes, no te cobraremos por perderte</p>
    </v-row>
  </v-container>
</template>

<script>
export default {
  
}
</script>

<style scoped>
.icon{
  font-size: 10rem !important;
}
.icon2{
  font-size: 5rem !important;
}
.icon3{
  font-size: 13rem !important;
}
</style>
