import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import CodeView from '../views/CodeView.vue'
import TerminosView from '../views/TerminosView.vue'
import PoliticaView from '../views/PoliticaView.vue'
import SoportView from '../views/SoportView.vue'
import NotFound from '../views/NotFound.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/not-found',
    name: 'not_found',
    component: NotFound
  },
  {
    path: '/:name',
    name: 'home',
    component: HomeView
  },
  {
    path: '/:name/code',
    name: 'code',
    component: CodeView
  },
  {
    path: '/:name/terminos',
    name: 'terminos',
    component: TerminosView
  },
  {
    path: '/:name/politica',
    name: 'politica',
    component: PoliticaView
  },
  {
    path: '/:name/soporte',
    name: 'soporte',
    component: SoportView
  },
]

const router = new VueRouter({
  mode: "history",
  routes
})

export default router
