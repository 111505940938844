<template>
  <div>
    <div class="background" v-if="assets" :style="{ backgroundImage: 'url(' + assets.background_url + ')' }"></div>
    <div class="content d-flex flex-column pa-12" :class="assets.dark?'white--text':'black--text'">
      <div class="">
        <div v-html="assets.thank_you_text_top"></div>
      </div>
      <div class="">
        <div class="secondary py-4 px-8 px-lg-15 mt-9" v-if="assets.code">
          <div class="d-flex justify-center">
            <p v-if="assets.code" class="code text-center white--text font-weight-bold mb-0">{{assets.code}}</p>
            <img src="@/assets/copy.svg" class="ml-3 mouse icon-responsive" @click="copyToClipboard(assets.code)">
          </div>
          <p class="text px-0 px-lg-15 white--text mt-4 font-weight-bold text-center">Se ha enviado el siguiente código al correo : <span class="complement--text darken-2" v-if="assets">{{assets.personMail}}</span></p>
        </div>
        <div class="secondary py-4 px-8 px-lg-15 mt-9" v-else>
          <p class="text px-0 px-lg-15 white--text mt-4 font-weight-bold text-center">Consigue tu codigo <router-link :to="{name:'home'}">aquí</router-link></p>
        </div>
      </div>
      <div class=" mt-9">
        <div v-html="assets.thank_you_text_bottom"></div>
      </div>
      
      <v-snackbar
        v-model="copied"
        bottom
      >
      <div class="d-flex">
        Copiado al portapeles
        <v-spacer></v-spacer>
        <v-btn color="secondary" small @click.native="copied = false" class="button-text">Cerrar</v-btn>
      </div>
      </v-snackbar>
    </div>
    <v-footer
      dark
      padless
      absolute
    >
    <div class="py-8 secondary"></div>
    </v-footer>
  </div>
</template>

<script>
export default {
  props:['assets'],
  data() {
    return {
      image: "http://drive.google.com/uc?export=view&id=1h2WPTF7x6kd1DtOZsSNnAJ-ykq4eHHMY",
      copied: false
    }
  },
  methods:{
    copyToClipboard(str) {
      const el = document.createElement('textarea')
      el.value = str
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      this.copied = true
    }
  }
}
</script>

<style scoped>

  a{
    font-weight: 800;
  }
  .code{
    font-size: 72px;
  }
  .gracias-text{
    font-size: 36px;
  }
  .mouse{
    cursor: pointer;
  }
  .icon-responsive{
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
    width: 6%;
    height: auto;
  }
  @media (max-width: 1000px){
    .code{
      font-size: 42px;
    }
    .gracias-text{
      font-size: 26px;
    }
    .text{
      font-size: 18px;
    }
  }
</style>
