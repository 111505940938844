<template>
  <div  v-if="assets" >
    <div class="background" :style="{ backgroundImage: 'url(' + assets.background_url + ')' }"></div>
    <div v-if="assets.terms && assets.terms.content_policy" class="container content" :class="assets.dark ? 'white--text' : 'black--text'" v-html="assets.terms.content_policy">
    </div>
  </div>
</template>

<script>
export default {
  props:['assets'],
  data() {
    return {
    }
  },
  mounted() {
    // console.log('%cTerminosView.vue line:17 this.assets', 'color: #26bfa5;', this.assets);
  },
  methods: {
    // getAssets(){
    //   this.axios.get(`/v1/landing/${this.$route.params.name}`,{
    //     headers: {
    //       'Access-Control-Allow-Origin': '*',
    //       'Access-Control-Allow-Headers': '*',
    //       'Content-Type': 'application/json',
    //     } 
    //   }).then((response) => {
    //     this.$vuetify.theme.themes.light.primary = response.data.primary_color
    //     this.$vuetify.theme.themes.light.secondary = response.data.secondary_color
    //     this.$vuetify.theme.themes.light.complement = response.data.third_color?response.data.third_color:"blue"

    //     this.assets = response.data
    //     this.$emit('getLogo', response.data.logo_url)
    //   })
    // },
  },
};
</script>

<style>

ol {
  margin-left: 30px;
}
</style>
