<template>
  <div  v-if="assets" >
    <div class="background" :style="{ backgroundImage: 'url(' + assets.background_url + ')' }"></div>
    <div class="container content text-center" :class="assets.dark ? 'white--text' : 'black--text'" >
      <v-card class="" elevation="11" outlined>
        <v-card-text class="py-10">
          <!-- <p>{{this.$route.params.name}}</p> -->
          <p class="ma-1 text-subtitle-1">Para soporte con tu código de redención:</p>

          <p class="mt-2">Horario de Atención</p>
          <p class="ma-0">Lunes a Sábado de 08:00 a 22:00 hora local</p>
          <p class="ma-0">Domingo de 08:00 a 20:00 hora local</p>
          
         
          <p class="ma-0 mt-6">Si necesitas ayuda para redimir tú código, contact a:</p>
          <a class="" target="_blank" href="mailto:ejjc85@hp.com">ejjc85@hp.com</a>
          
          <p class="ma-0 mt-6">Si necesitas ayuda para activar tu usuario Vix, acude a:</p>
          <a class="" target="_blank" href="https://ayuda.vix.com/hc/es-419">https://ayuda.vix.com/hc/es-419</a>

        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  props:['assets'],
  data(){
    return {
    }
  },
  computed:{
    phoneNumers(){
      if(this.$route.params.name === "vix"){
        return "+523319309462"
      }
      return "+523319309511"
    }
  }
};
</script>

<style>

ol {
  margin-left: 30px;
}
</style>
